<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img
        src="../assets/Logo_BizscreenFinal_Blanc.svg"
        style="max-width: 48px"
      />
    </CSidebarBrand>

    <ul class="c-sidebar-nav h-100 ps" style="position: relative">
      <li class="c-sidebar-nav-item" data-v-afcd1a82="">
        <a
          href="#/dashboard"
          class="c-sidebar-nav-link"
          target="_self"
          :class="{ 'c-active': activeMenu == 'dashboard' ? true : false }"
          @click="menuChange('dashboard')"
        >
          <span class="material-symbols-outlined iconhelp"> dashboard </span
          >{{ $t("dashboard") }}
        </a>
      </li>
      <li class="c-sidebar-nav-item">
        <a
          href="#/dashboard/gallery"
          class="router-link-exact-active c-sidebar-nav-link"
          target="_self"
          aria-current="page"
          :class="{ 'c-active': activeMenu == 'gallery' ? true : false }"
          @click="menuChange('gallery')"
        >
          <span class="material-symbols-outlined iconhelp"> photo_library </span>
          {{ $t("gallery") }}
        </a>
      </li>
      <li class="c-sidebar-nav-item">
        <a
          href="#/dashboard/account"
          class="router-link-exact-active c-sidebar-nav-link"
          target="_self"
          aria-current="page"
          :class="{ 'c-active': activeMenu == 'abo' ? true : false }"
          @click="menuChange('abo')"
        >
          <span class="material-symbols-outlined iconhelp">
            card_membership
          </span>
          {{ $t("my_account") }}
        </a>
      </li>
      <!--li class="c-sidebar-nav-item">
        <a
          href="#/about"
          class="router-link-exact-active c-sidebar-nav-link"
          :class="{ 'c-active': activeMenu == 'about' ? true : false }"
          @click="menuChange('about')"
          target="_self"
          aria-current="page"
        >
          <span class="material-symbols-outlined iconhelp"> help </span>
          {{ $t("about") }}
        </a>
      </li-->
      
      <li class="c-sidebar-nav-item">
        <a
          href="#/dashboard/setting"
          class="router-link-exact-active c-sidebar-nav-link"
          target="_self"
          aria-current="page"
          :class="{ 'c-active': activeMenu == 'setting' ? true : false }"
          @click="menuChange('setting')"
        >
          <span class="material-symbols-outlined iconhelp"> settings </span>
          {{ $t("settings") }}
        </a>
      </li>
      <li class="c-sidebar-nav-item" style="flex-grow: 1">
        <a
          href="#/dashboard/agency"
          class="router-link-exact-active c-sidebar-nav-link"
          target="_self"
          aria-current="page"
          :class="{ 'c-active': activeMenu == 'agency' ? true : false }"
          @click="menuChange('agency')"
        >
          <span class="material-symbols-outlined iconhelp"> switch_access </span
          >{{ $t("demande_crea") }}
        </a>
      </li>

      <li v-show="!minimize">
        <div style="padding-bottom: 8px; color: rgba(255, 255, 255, 0.7)">
          <div style="text-align: center">{{ $t("espace_stockage") }}</div>
          <div
            style="
              display: flex;
              gap: 5px;
              align-items: center;
              justify-content: space-evenly;
            "
          >
            <div>{{ total_media }}/{{ max_storage }}{{ $t("Go") }}</div>
            <CProgress class="mn" color="warning">
              <CProgressBar :value="percent_storage"
                >{{ percent_storage }}%</CProgressBar
              >
            </CProgress>
          </div>
        </div>
      </li>

      <div class="ps__rail-x" style="left: 0px; bottom: 0px">
        <div
          class="ps__thumb-x"
          tabindex="0"
          style="left: 0px; width: 0px"
        ></div>
      </div>
      <div class="ps__rail-y" style="top: 0px; right: 0px">
        <div
          class="ps__thumb-y"
          tabindex="0"
          style="top: 0px; height: 0px"
        ></div>
      </div>
    </ul>

    <!--CRenderFunction flat :content-to-render="$options.nav"/-->
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav,
  data() {
    return {
      activeMenu: "dashboard",
      total_media: 0,
      max_storage: 0,
      percent_storage: 0,
    };
  },
  created() {
    const self = this;
    window.axios.get("/api/user/storage").then((rep) => {
      self.total_media = (rep.data.total_media / 1000000000).toFixed(2);
      if (rep.data.max / 1000000000 < 1)
        self.max_storage = (rep.data.max / 1000000000).toFixed(2);
      else self.max_storage = rep.data.max / 1000000000;
      self.percent_storage = Math.ceil(
        (self.total_media / self.max_storage) * 100
      );
    });
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  methods: {
    menuChange(menu) {
      this.activeMenu = menu;
    },
  },
};
</script>

<style scoped>
.iconhelp {
  font-size: 20px;
  padding-left: 3px;
  padding-right: 18px;
}

.mn {
  min-width: 150px;
}
</style>
